import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBackdrop from "components/Custom/MDBackdrop";
import MDDialog from "components/Custom/MDDialog";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Switch from "@mui/material/Switch";
import { useSnackbar } from "notistack";
import OutlinedInput from "@mui/material/OutlinedInput";

function CreateService() {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [file, setFile] = useState();
  const [services, setServices] = useState([]);
  // const [status, setStatus] = useState(0);
  const fetchAllServices = async () => {
    setIsLoading(true);
    await axios.get(
      `${process.env.REACT_APP_BASE_URL}service`
    )
      .then(res => {
        if (res.data.success) {
          setServices(res.data.data);
          setIsLoading(false);
        }
        else {
          enqueueSnackbar(res.data.message, { variant: "error" });
          setIsLoading(false);
        }
      })
      .catch(err => {
        console.log(err);
        setIsLoading(false);
        enqueueSnackbar('Error While Fetching Services !!!', { variant: 'error' })
      })
  }
  useEffect(() => {
    fetchAllServices();
  }, [])
  const getValues = () => {
    return {
      subserviceName: "",
      pricePerKg: "",
      pricePerPiece: "",
      service_id: "",
    };
  };
  const handleFile = (event) => {
    setFile(event.target.files[0]);
  };
  const createservice = async (values) => {
    setIsLoading(true);
    var bodyFormData = new FormData();
    bodyFormData.append("subserviceName", values.subserviceName);
    bodyFormData.append("pricePerKg", values.pricePerKg);
    bodyFormData.append("pricePerPiece", values.pricePerPiece);
    bodyFormData.append("service_id", values.service_id);
    bodyFormData.append("subserviceImage", file);
    await axios.post(
      `${process.env.REACT_APP_BASE_URL}subservice`,bodyFormData
    )
      .then(res => {
        if (res.data.success) {
          enqueueSnackbar("Sub-service Created Successfully !!!", { variant: "success" })
          setIsLoading(false);
          setValues(getValues);
          navigate("/services");
        }
        else {
          enqueueSnackbar(res.data.message, { variant: "error" });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        enqueueSnackbar('Error While Creating Services !!!', { variant: 'error' })
      });
  };

  const [values, setValues] = useState(getValues);

  const handleChange = (event) => {
    setValues((prevValues) => ({
      ...prevValues,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const { subserviceName, pricePerKg, pricePerPiece, service_id } = values;

    // Check if any field is empty
    if (!subserviceName || !pricePerKg || !pricePerPiece || !service_id || !file) {
      enqueueSnackbar("Please fill in all fields", { variant: "error" });
      return;
    }
    createservice(values);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Create Service
                </MDTypography>
              </MDBox>

              <MDBox p={3} component="form" role="form">
                <MDBox p={1}>
                  <MDInput
                    type="text"
                    label="Sub-Service Name"
                    value={values.subserviceName}
                    name="subserviceName"
                    margin="dense"
                    fullWidth={true}
                    onChange={handleChange}

                  />
                </MDBox>

                <MDBox p={1}>
                  <MDInput
                    type="number"
                    label="Price Per Kg"
                    value={values.pricePerKg}
                    name="pricePerKg"
                    fullWidth={true}
                    onChange={handleChange}
                  />
                </MDBox>
                <MDBox p={1}>
                  <MDInput
                    type="number"
                    label="Price Per Piece"
                    value={values.pricePerPiece}
                    name="pricePerPiece"
                    margin="dense"
                    fullWidth={true}
                    onChange={handleChange}
                  />
                </MDBox>
                <MDBox p={1}>
                <InputLabel id="demo-multiple-name-label3">Associated Services</InputLabel>
              <Select
                  sx={{
                    height: 50,
                  }}
                  labelId="demo-multiple-name-label3"
                  id="demo-multiple-name2"
                  defaultValue={values.service_id}
                  name="service_id"
                  onChange={handleChange}
                  input={<OutlinedInput label="Name" />}
                  fullWidth
                >
                  {services.map((name) => (
                    <MenuItem
                      key={name.service_id}
                      value={name.service_id}
                    //   style={getStyles(name.name, values.service, theme)}
                    >
                      {name.serviceName}
                    </MenuItem>
                  ))}
                </Select>
                </MDBox>
                <MDBox p={1}>
                <InputLabel id="demo-multiple-name-label3">Sub Service Image</InputLabel>
                  <MDInput type="file" name="file" onChange={handleFile} fullWidth />
                </MDBox>

                <Grid container direction="row" justifyContent="flex-end" p={2}>
                  <MDButton variant="gradient" color="info" onClick={handleSubmit}>
                    Submit
                  </MDButton>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default CreateService;
