import axios from "axios";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from '@mui/icons-material/Delete';
import { Avatar } from '@mui/material';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MDButton from "components/MDButton";
import React, { useState, useEffect } from "react";
import LaunchIcon from "@mui/icons-material/Launch";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DataTable from "examples/Tables/DataTable";
import Loader from "components/Custom/Loader";
import MDBackdrop from "components/Custom/MDBackdrop";
import MDDialog from "components/Custom/MDDialog";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
// import ExportCSV from "components/Custom/CompanyView/ExportCSV";
// import POPUploadUserCSV from "components/Custom/CompanyView/POPUploadUserCSV";
// import '../css/Common.css';
// import { saveAs } from "file-saver";
// import DownloadIcon from "@mui/icons-material/Download";

export default function Services() {
  const { enqueueSnackbar } = useSnackbar();
  // const axios = require("axios");
  const [isLoading, setIsLoading] = useState(true);
  const [isDisabled1, setIsDisabled1] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [dataRec, setDataRec] = useState(null);
  const [isBackdrop, setIsBackdrop] = useState(false);
  const [isDialog, setIsDialog] = useState(false);
  // const [allTasks, setAllTasks] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const navigate = useNavigate();
  const fetchAllTasks = async () => {
    setIsLoading(true);
    await axios.get(
      `${process.env.REACT_APP_BASE_URL}subservice`
    )
      .then(res => {
        if (res.data.success) {
          const updatedData = res.data.data.map(row => ({
            ...row,
            subserviceImage: `${process.env.REACT_APP_BASE_IMAGE_URL}${row.subserviceImage}` // Assuming subserviceImage contains the image filename
          }));
          setDataRec(updatedData);
          // setDataRec(res.data.data);
          setIsLoading(false);
        }
        else {
          enqueueSnackbar(res.data.message, { variant: "error" });
          setIsLoading(false);
        }
      })
      .catch(err => {
        console.log(err);
        setIsLoading(false);
        enqueueSnackbar('Error While Fetching Sub-Services !!!', { variant: 'error' })
      })
  }
  // useEffect(() => {
  //   if(!isloggedin)
  //   {
  //       history.push('/login'); 
  //   }
  // }, []);
  useEffect(() => {
    fetchAllTasks();
  }, [])
console.log(dataRec)

  // const handleEdit = (row_data) => {
  //   navigate("/users/view", { state: row_data });
  // };

  const handleCreateService = () => {
    navigate("/services/create");
  };
  const handleDelete = async (row_data) => {
    // var bodyFormData = new FormData();
    // bodyFormData.append("id", row_data.id);
    await axios.delete(
      `${process.env.REACT_APP_BASE_URL}subservice/${row_data.id}`
    )
      .then(response => {
        console.log(response);

        if (response.data.success === true) {
          console.log(response);
          enqueueSnackbar('Sub-Services Deleted Successfully !!!', { variant: 'success' });
          window.location.reload();
        } else {
          console.log("status is false ");
          enqueueSnackbar(response.data.message, { variant: 'error' });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar('Error While Deleting Sub-Services !!!', { variant: 'error' })
      });
  };

  // const dialogSubmit = () => {
  //   window.location.reload();
  // };
  const columns = [
    { Header: "Id", accessor: "id", align: "center" },
    { 
      Header: "Sub Service Image", 
      accessor: "subserviceImage", 
      align: "center",
      Cell: ({ row }) => (
        <Avatar alt={row.original.subserviceName} src={row.original.subserviceImage} />
      )
    },
    { Header: "Name", accessor: "subserviceName", align: "center" },
    { Header: "Price Per Kg", accessor: "pricePerKg", align: "center" },
    { Header: "price Per Piece", accessor: "pricePerPiece", align: "center" },
    { Header: "Service Id", accessor: "service_id", align: "center" },
    {
      Header: "Action",
      accessor: "action",
      align: "center",
      Cell: (row) => (
        <div>
          {/* <MDButton
            onClick={(e) => handleEdit(row.row.original)}
            variant="gradient"
            color="info"
            iconOnly
          >
            <LaunchIcon />
          </MDButton>
          <MDButton
            sx={{
              marginLeft: 1,
            }}
            onClick={(e) => download1(row.row.original.id)}
            variant="gradient"
            color="info"
            iconOnly
          ><Tooltip title="Download communating companies">
              <DownloadIcon />
            </Tooltip>
          </MDButton>
          <MDButton
            sx={{
              marginLeft: 1,
            }}
            onClick={(e) => download2(row.row.original.id)}
            variant="gradient"
            color="info"
            iconOnly
          ><Tooltip title="Download assign companies">
              <DownloadIcon />
            </Tooltip>
          </MDButton> */}
          <MDButton
            sx={{
              marginLeft: 1,
            }}
            onClick={(e) => handleDelete(row.row.original)}
            variant="gradient"
            color="info"
            // color="secondary"
            iconOnly
          >
            {/* <LaunchIcon /> */}
            <DeleteIcon />
          </MDButton>
        </div>
      ),
    },
  ];

  var rows = dataRec;

  return (
    <DashboardLayout>
      <MDBackdrop isBackdrop={isBackdrop} />
      {/* <MDDialog
        isDialog={isDialog}
        dialogText={dialogMessage}
        action={dialogSubmit}
        buttonText="Go to Users"
      />
      <ExportCSV
        isDialog={isDisabled1}
        onClose={setIsDisabled1}
        data={dataRec}
      />
      <POPUploadUserCSV
        isDialog={isDisabled}
        onClose={setIsDisabled}
      /> */}
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                  <MDTypography variant="h6" color="white">
                    All Services
                  </MDTypography>
                  <MDBox className="abc">
                  <MDButton onClick={() => handleCreateService()} variant="outlined" color="white">
                    Create Service
                  </MDButton>
                  </MDBox>
                </Grid>
              </MDBox>

              <MDBox pt={3}>
                {isLoading ? (
                  <Loader />
                ) : (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={true}
                    entriesPerPage={true}
                    showTotalEntries={false}
                    canSearch={true}
                  />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
